import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"
const SEO = ({ title, lang, description, image, article, schemaMarkup, publishTime, modifiedTime }) => {
  const { pathname } = useLocation()
  const { site } = useStaticQuery(query)
  const {
    defaultTitle,
    defaultDescription,
    siteUrl,
    defaultImage,
    twitterUsername,
  } = site.siteMetadata
  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${image || defaultImage}`,
    url: `${siteUrl}${pathname}`,
  }
  const isConfirm = pathname === '/confirmation'
  return (
    <Helmet title={seo.title} htmlAttributes={{ lang }}>
      { isConfirm ? <meta name="robots" content="noindex,nofollow"/> : <meta name="robots" content="index,follow"/> }
      { (publishTime ? true : null) && <meta property="article:published_time" content={publishTime} />  }
      { (modifiedTime ? true : null) && <meta property="article:modified_time" content={modifiedTime} />  }
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      {seo.url && <meta property="og:url" content={seo.url} />}
      {(article ? true : null) && <meta property="og:type" content="article" /> } 
      {(article ? true : null) && <meta property="article:publisher" content="https://twitter.com/brandinavian/" /> }
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      {seo.image && <meta property="og:image" content={seo.image} />}
      <meta name="twitter:card" content="summary_large_image" />
      {twitterUsername && (
        <meta name="twitter:creator" content={twitterUsername} />
      )}
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}
      {seo.image && <meta name="twitter:image" content={seo.image} />}
      
      {schemaMarkup &&
        <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>  
      }
    </Helmet>
  )
}
export default SEO
SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
  publishTime: PropTypes.string,
  modifiedTime: PropTypes.string,
}
SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
  publishTime: null,
  modifiedTime: null,
}
const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        twitterUsername
      }
    }
  }
`