import React from "react";
import SEO from '../components/SEO'


import LayoutSmall from "../components/layoutSmall";
import BannerConfirm from "../components/BannerConfirm";


const Confirmation = () => (
  <LayoutSmall altLogo={'black'}>
    <SEO lang="en" title="Submission confirmation" />
   <BannerConfirm />
  </LayoutSmall>
);

export default Confirmation;